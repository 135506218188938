<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M.243,7.444l10.31,6.186c.447,.268,.947,.401,1.448,.401s1.001-.134,1.448-.401l10.31-6.186c.15-.09,.243-.253,.243-.429s-.092-.339-.243-.429L13.448,.401c-.893-.537-2.003-.537-2.896,0L.243,6.587c-.15,.09-.243,.253-.243,.429s.092,.339,.243,.429ZM11.067,1.259c.576-.346,1.291-.346,1.866,0l9.595,5.757-9.595,5.757c-.575,.346-1.291,.346-1.866,0L1.472,7.016,11.067,1.259Zm12.933,18.241c0,.276-.224,.5-.5,.5h-3.5v3.5c0,.276-.224,.5-.5,.5s-.5-.224-.5-.5v-3.5h-3.5c-.276,0-.5-.224-.5-.5s.224-.5,.5-.5h3.5v-3.5c0-.276,.224-.5,.5-.5s.5,.224,.5,.5v3.5h3.5c.276,0,.5,.224,.5,.5Zm-.071-9.157c.142,.236,.065,.544-.171,.686l-11.5,6.9c-.079,.048-.168,.071-.257,.071s-.178-.023-.257-.071L.243,11.028c-.237-.142-.313-.449-.171-.686s.449-.316,.686-.172l11.243,6.746,11.243-6.746c.237-.145,.544-.064,.686,.172Zm-11.5,10.914c-.094,.156-.259,.243-.429,.243-.087,0-.176-.022-.257-.071L.243,14.528c-.237-.142-.313-.449-.171-.686s.449-.315,.686-.172l11.5,6.9c.237,.142,.313,.449,.171,.686Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddFacture'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
